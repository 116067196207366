<template>
  <HomePageBuilder
    v-if="enableHomePageBuilder && enablePublicHomePageBuilder"
    :content="content"
  />
  <HomePageHardcoded v-else />
</template>

<script>
import HomePageHardcoded from './index.hardcode.vue'
import HomePageBuilder from '~contemporaryTheme/pages/index'
import useFeatureFlags from '@/composables/useFeatureFlags'
import {
  PAGE_BUILDER_HOME_PAGE,
  PUBLIC_PAGE_BUILDER_HOME_PAGE,
} from '@/constants/featureFlags'

export default {
  setup() {
    const {
      enableHomePageBuilder,
      enablePublicHomePageBuilder,
    } = useFeatureFlags({
      enableHomePageBuilder: PAGE_BUILDER_HOME_PAGE,
      enablePublicHomePageBuilder: PUBLIC_PAGE_BUILDER_HOME_PAGE,
    })
    return { enableHomePageBuilder, enablePublicHomePageBuilder }
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    HomePageBuilder,
    HomePageHardcoded,
  },
}
</script>
