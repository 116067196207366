<template>
  <div>
    <div
      data-test="hero-section"
      class="relative bg-theme-base overflow-hidden"
    >
      <div class="max-w-screen-xl mx-auto">
        <div class="relative z-10 pb-8 bg-theme-base sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            aria-hidden="true"
            class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 color-theme-base transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            role="img"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div class="pt-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div class="sm:text-center lg:text-left">
              <h1 class="text-4xl tracking-tight leading-10 sm:text-5xl sm:leading-none md:text-6xl color-theme-lightest">
                Stanford Bulletin
                <br>
                <span>2022-2023</span>
              </h1>
              <div class="mt-3 color-theme-lightest sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                <div class="mb-4">Use this guide to navigate your academic journey:</div>
                <ul class="ml-2">
                  <li
                    v-for="guide in heroBulletList"
                    :key="guide"
                    class="flex items-center"
                  >
                    <BaseIcon
                      icon="circle"
                      class="mr-3"
                    />
                    {{ guide }}
                  </li>
                </ul>
                <div class="mt-4">The opportunities are boundless. Your opportunity is now.</div>
              </div>
              <div class="mt-5 sm:mt-8 relative">
                <div class="mt-3">
                  <NuxtLink
                    to="/programs"
                    class="inline-block items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md color-theme-base bg-theme-lightest transform hover:scale-105 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    <span class="ui-font">Explore Programs</span>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          :src="heroImage"
          alt=""
        >
      </div>
    </div>
    <div class="mt-12 py-12 bg-white">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Academics at Stanford
          </h2>
          <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            With more than 7,000 undergraduates and 9,000 graduates studying across our seven schools on our one campus, Stanford’s abundant offerings provide our community rich experiences and endless possibilities to pursue interests,
            master
            skills, and develop one’s whole self.
          </p>
        </div>
        <div class="mt-10">
          <ul class="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li class="mt-10 md:mt-0">
              <div class="flex">
                <div class="flex-shrink-0">
                  <a
                    aria-label="Undergraduate Education"
                    href="/pages/bWfU3zur2ZYuC3wbpoST"
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-theme-base text-white"
                  >
                    <BaseIcon icon="tree" />
                  </a>
                </div>
                <div class="ml-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <a
                      href="/pages/bWfU3zur2ZYuC3wbpoST"
                      class="hover:text-indigo-500 transition ease-in-out duration-150"
                    >Undergraduate Education</a>
                  </h3>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    The Vice Provost for Undergraduate Education (VPUE) provides classes and seminars, opportunities for research and travel abroad, along with resources, support, and programs for all undergraduate students at Stanford.
                  </p>
                </div>
              </div>
            </li>
            <li class="mt-10 md:mt-0">
              <div class="flex">
                <div class="flex-shrink-0">
                  <a
                    aria-label="Schools"
                    href="/pages/RatNdTFWgP5ePGl64P0j"
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-theme-base text-white"
                  >
                    <BaseIcon icon="book" />
                  </a>
                </div>
                <div class="ml-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <a
                      href="/pages/RatNdTFWgP5ePGl64P0j"
                      class="hover:text-indigo-500 transition ease-in-out duration-150"
                    >Schools</a>
                  </h3>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    Stanford is unique in having seven schools co-located on one contiguous campus, and all of them possess exceptional breadth and depth of excellence. This naturally facilitates multidisciplinary collaboration.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex">
                <div class="flex-shrink-0">
                  <a
                    aria-label="Graduate Education"
                    href="/pages/IdGtReJ0bVkpnvyuJDJD"
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-theme-base text-white"
                  >
                    <BaseIcon icon="archway" />
                  </a>
                </div>
                <div class="ml-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <a
                      href="/pages/IdGtReJ0bVkpnvyuJDJD"
                      class="hover:text-indigo-500 transition ease-in-out duration-150"
                    >Graduate Education</a>
                  </h3>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    The Office of the Vice Provost for Graduate Education (VPGE) provides professional development resources, fellowship programs, interschool networking opportunities, and other support for Stanford’s master's, doctoral,
                    and professional degree students in over 200 graduate degree programs within all seven schools.
                  </p>
                </div>
              </div>
            </li>
            <li class="mt-10 md:mt-0">
              <div class="flex">
                <div class="flex-shrink-0">
                  <a
                    aria-label="Academic Policies"
                    href="https://studentservices.stanford.edu/more-resources/student-policies"
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-theme-base text-white"
                  >
                    <BaseIcon icon="list" />
                  </a>
                </div>
                <div class="ml-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    <a
                      href="https://studentservices.stanford.edu/more-resources/student-policies"
                      class="hover:text-indigo-500 transition ease-in-out duration-150"
                    >Academic Policies</a>
                  </h3>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    Students are expected to know and abide by Stanford University policies, rules, requirements, and regulations. These pertain to registration, academic performance, student conduct, health and safety, housing, use of the
                    libraries and computing resources, intellectual property, payment of fees, and more.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-8">
          <img
            class="w-full rounded"
            :src="campusImage"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="bg-gray-50">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl leading-9 tracking-tight sm:text-4xl sm:leading-10 mb-2">
          Student Services Center
        </h2>
        <p class="text-gray-500 mb-4">We’re here to help you and your family navigate your academic journey. Think of us as your go-to partner and lean on us to answer questions about enrollment, academic policies, tuition and
          fees, and more.
        </p>
        <p class="mb-4">
          Reach out to us through one of these four options:
        </p>
        <div class="color-theme-base">
          <div class="mb-1">
            <a
              target="_blank"
              href="https://stanford.service-now.com/student_services"
            >
              <BaseIcon
                icon="arrow-circle-right"
                class="mr-2"
              />
              Submit an online request for support
            </a>
          </div>
          <div class="mb-1">
            <a
              target="_blank"
              href="https://go.oncehub.com/StudentServicesCenterSSC"
            >
              <BaseIcon
                icon="arrow-circle-right"
                class="mr-2"
              />
              Make a virtual Zoom appointment
            </a>
          </div>
          <div class="mb-1">
            <a
              target="_blank"
              href="https://stanford.service-now.com/student_services"
            >
              <BaseIcon
                icon="arrow-circle-right"
                class="mr-2"
              />
              Chat with us live
            </a>
          </div>
          <div class="mb-1">
            <a
              target="_blank"
              href="tel:8669937772"
            >
              <BaseIcon
                icon="arrow-circle-right"
                class="mr-2"
              />
              Give us a call: (866) 993-7772
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import heroImage from '@/assets/themes/stanford/images/hero.jpeg'
import campusImage from '@/assets/themes/stanford/images/campus.jpeg'
export default {
  data() {
    return {
      heroImage,
      campusImage,
      heroBulletList: [
        'Explore and compare degree programs',
        'Discover and develop your passions',
        'Find incredible courses',
        'Understand your options',
        'Review detailed degree requirements',
        'Plan for your success',
      ],
    }
  },
}
</script>
<style scoped>
h1 {
  color: var(--h1HeaderFontColor, var(--text-gray-900));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 800);
}
h2 {
  color: var(--h2HeaderFontColor, var(--text-gray-900));
  font-family: var(--h2HeaderFontTypeface, inherit);
  font-weight: var(--h2HeaderFontWeight, 800);
}
h3 {
  color: var(--h3HeaderFontColor, var(--text-gray-900));
  font-family: var(--h3HeaderFontTypeface, inherit);
  font-weight: var(--h3HeaderFontWeight, 800);
}
h4,
p {
  font-family: var(--textFontTypeface, inherit);
}
h4 {
  color: var(--textFontColor, var(--text-gray-900));
  font-weight: var(--textFontWeight, 500);
}
p {
  color: var(--textFontColor, var(--text-gray-500));
  font-weight: var(--textFontWeight, inherit);
}
</style>
